import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEOadvanced"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"

const SuccessPage = () => (
  <>
    <SEO
      title="Дякую, за звернення Б'юті-майстра"
      description="Повідомлення успішно відправлено, зв'яжуся з вами, як тільки його отримаю."
    />
    <header>
      <Navigation menuVariant='light' />
      <section className='hero py-5 text-center successhero'>
        <Container className='my-5'>
          <h1 className='mb-4'>Дякую, за звернення!</h1>
          <p>
            Ваше повідомлення успішно відправлено, зв'яжуся з вами, як тільки
            його отримаю.
          </p>
          <p>
            Зазвичай відповідаю протягом години чи раніше. Якщо зайнята роботою,
            то відповідаю всім в обідню перерву або ввечері!
          </p>
          <Link to='/' className='btn btn-danger'>
            Перейти на головну сторінку
          </Link>
        </Container>
      </section>
    </header>
    <Footer />
  </>
)

export default SuccessPage
